<template>
  <div class="container-fluid">
this is a cdn list for projects lol, u must login.
  </div>
</template>
<!--- <script>
  export default {
    name: 'home',
    components: {},
  };
</script> -->
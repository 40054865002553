<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#"><img style="width: 60px;" src="https://cdn.justmammtlol.wtf/images/justmammt.jpg"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#"></a>
      </li>
      <li class="nav-item">
        <router-link to="/" class="nav-link headertitles">Home</router-link>
      </li>

      <li class="nav-item active">
        <a class="nav-link" href="https://www.justmammtlol.wtf/">Main Site</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">   <router-link to="/login" class="nav-link headertitles">Login</router-link></button>
    </form>
  </div>
</nav>
       

  <router-view/>
</template>

<style>
.headertitles {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: inherit;
  text-decoration: none;
}
.nav-link.router-link-active {
  color: #036281;
}

</style>


